<script>
import { mapGetters } from "vuex";
import {
  departmentMethods,
  employeeMethods,
  jobTitleMethods,
} from "../../../state/helpers";
/**
 * Employee Card component
 */
export default {
  props: ["employee"],
  components: {},
  computed: {
    ...mapGetters("employees", ["employees"]),
    ...mapGetters("departments", ["departments"]),
  },
  data() {
    return {};
  },
  watch: {
    employees: {
      immediate: true,
      handler(_, oldVal) {
        if (!oldVal) this.getEmployees();
      },
    },
    departments: {
      immediate: true,
      handler(_, oldVal) {
        if (!oldVal) this.getDepartments();
      },
    },
  },
  methods: {
    ...departmentMethods,
    ...employeeMethods,
    ...jobTitleMethods,
  },
};
</script>

<template>
  <b-card class="text-center">
    <img
      class="rounded-circle avatar-xl"
      alt="200x200"
      src="@/assets/images/users/avatar-3.jpg"
      data-holder-rendered="true"
    />
    <b-card-body>
      <b-card-title>
        <span v-if="!employee.user?.profile">{{ employee.user?.name }}</span>
        {{ employee.user?.profile?.first_name }}
        {{ employee.user?.profile?.last_name }}
      </b-card-title>
      <b-list-group flush>
        <b-list-group-item
          class="d-flex justify-content-between align-items-center"
        >
          <small>{{ $t("employee.work_email") }}</small>
          {{ employee["email"] || "---" }}
        </b-list-group-item>
        <b-list-group-item
          class="d-flex justify-content-between align-items-center"
        >
          <small>{{ $t("employee.phone") }}</small>
          {{ employee["phone"] || "---" }}
        </b-list-group-item>
        <b-list-group-item
          class="d-flex justify-content-between align-items-center"
        >
          <small>{{ $t("employee.department") }}</small>
          {{
            departments?.find(
              (d) => parseInt(d.id) === parseInt(employee.department_id)
            )?.name || "---"
          }}
        </b-list-group-item>
        <b-list-group-item
          class="d-flex justify-content-between align-items-center"
        >
          <small>{{ $t("employee.reporting_to") }}</small>
          {{
            employees.find(
              (e) => parseInt(e.id) === parseInt(employee.employee_id)
            )?.user?.profile?.full_name || "---"
          }}
        </b-list-group-item>
        <b-list-group-item
          class="d-flex justify-content-between align-items-center"
        >
          <small>{{ $t("employee.start_date") }}</small>
          {{ employee["contract_start"] | moment("DD/MM/YYYY") }}
        </b-list-group-item>
        <b-list-group-item
          class="d-flex justify-content-between align-items-center"
        >
          <small>{{ $t("employee.to") }}</small>
          {{ employee["contract_end"] | moment("DD/MM/YYYY") }}
        </b-list-group-item>
        <b-list-group-item
          class="d-flex justify-content-between align-items-center text-capitalize"
        >
          <small>{{ $t("employee.period_of_employment") }}</small>
          {{ employee["contract_start"] | moment("from", "now", true) }}
        </b-list-group-item>
      </b-list-group>
    </b-card-body>
  </b-card>
</template>