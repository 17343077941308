<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import BasicInfo from "./basic-info";
import EmployeeCard from "./employee-card";
import RecentRequests from "../dashboard/recent-requests.vue";
import AttendanceLog from "../dashboard/attendance-log.vue";
import { employeeMethods } from "../../../state/helpers";
import { mapGetters } from "vuex";

/**
 * Profile component
 */
export default {
  components: {
    Layout,
    PageHeader,
    BasicInfo,
    EmployeeCard,
    RecentRequests,
    AttendanceLog,
  },
  computed: {
    ...mapGetters("employees", ["current_employee"]),
  },
  data() {
    return {
      title: `Profile`,
      attendanceLog: [
        {
          Date: "05-10-2022",
          first_in: "09:05",
          last_out: "17:00",
          hours: "07:55",
        },
        {
          Date: "04-10-2022",
          first_in: "10:00",
          last_out: "18:00",
          hours: "08:00",
          _rowVariant: "danger",
        },
        {
          Date: "03-10-2022",
          first_in: "09:00",
          last_out: "17:00",
          hours: "08:00",
        },
        {
          Date: "02-10-2022",
          first_in: "09:00",
          last_out: "16:00",
          hours: "07:00",
          _rowVariant: "warning",
        },
        {
          Date: "01-10-2022",
          first_in: "09:00",
          last_out: "17:00",
          hours: "08:00",
        },
      ],
      items: [
        {
          text: "HR",
        },
        {
          text: "Profile",
          active: true,
        },
      ],
    };
  },
  methods: {
    ...employeeMethods,
  },
  watch: {
    current_employee: {
      immediate: true,
      handler(_, oldVal) {
        if (!oldVal) this.getEmployees(this.$route.params.id);
      },
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-row>
      <b-col md="4">
        <EmployeeCard :employee="current_employee" />
      </b-col>
      <b-col md="8">
        <b-tabs content-class="mt-3" fill pills>
          <b-tab title="Basic Info" active>
            <BasicInfo :employee="current_employee" />
          </b-tab>
          <b-tab title="Requests">
            <RecentRequests title="requests" />
          </b-tab>
          <b-tab title="Attendance History">
            <AttendanceLog
              :log="attendanceLog"
              title="Here are your check-in and check-out records for the past period."
            />
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </Layout>
</template>