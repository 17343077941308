<script>
import { mapGetters } from "vuex";
import { jobTitleMethods, workPlanMethods } from "../../../state/helpers";
/**
 * Basic Info component
 */
export default {
  props: ["employee"],
  components: {},
  computed: {
    ...mapGetters("jobTitles", ["job_titles"]),
    ...mapGetters("workPlans", ["work_plans"]),
  },
  data() {
    return {};
  },
  methods: {
    ...jobTitleMethods,
    ...workPlanMethods,
  },
  created() {
    this.getJobTitles();
    this.getWorkPlans();
  },
};
</script>

<template>
  <div>
    <b-card :title="$t('employee.personal_info')">
      <b-row>
        <b-col md="4" class="mb-3">
          <p class="text-muted mb-0">{{ $t("employee.gender") }}</p>
          {{ $t(`gender.${employee.user?.profile?.gender}`) }}
        </b-col>
        <b-col md="4" class="mb-3">
          <p class="text-muted mb-0">{{ $t("employee.birth_date") }}</p>
          {{ employee.user?.profile?.birth_date | moment("DD MMM Y") }}
        </b-col>
        <b-col md="4" class="mb-3">
          <p class="text-muted mb-0">{{ $t("employee.other_name") }}</p>
          {{ employee.user?.profile?.other_name || "---" }}
        </b-col>
        <b-col md="4" class="mb-3">
          <p class="text-muted mb-0">{{ $t("employee.marital_status") }}</p>
          {{ $t(`marital_status.${employee.user?.profile?.marital_status}`) }}
        </b-col>
        <b-col md="4" class="mb-3">
          <p class="text-muted mb-0">{{ $t("employee.address") }}</p>
          {{ employee.user?.profile?.address || "---" }}
        </b-col>
        <b-col md="4" class="mb-3">
          <p class="text-muted mb-0">{{ $t("employee.military_status") }}</p>
          {{ $t(`military_status.${employee.user?.profile?.military_status}`) }}
        </b-col>
        <b-col md="4" class="mb-3">
          <p class="text-muted mb-0">{{ $t("employee.nationality") }}</p>
          {{ employee.user?.profile?.nationality || "---" }}
        </b-col>
        <b-col md="4" class="mb-3">
          <p class="text-muted mb-0">{{ $t("employee.national_id") }}</p>
          {{ employee.user?.profile?.national_id || "---" }}
        </b-col>
        <b-col md="4" class="mb-3">
          <p class="text-muted mb-0">{{ $t("employee.country") }}</p>
          {{ $t(`country.${employee.user?.profile?.country}`) }}
        </b-col>
        <b-col md="4" class="mb-3">
          <p class="text-muted mb-0">{{ $t("employee.religion") }}</p>
          {{ $t(`religion.${employee.user?.profile?.religion}`) }}
        </b-col>
        <b-col md="4" class="mb-3">
          <p class="text-muted mb-0">{{ $t("employee.number_of_kids") }}</p>
          {{ employee.user?.profile?.number_of_kids || "---" }}
        </b-col>
        <b-col md="4" class="mb-3">
          <p class="text-muted mb-0">{{ $t("employee.personal_email") }}</p>
          {{ employee.user?.email || "---" }}
        </b-col>
      </b-row>
    </b-card>
    <b-card :title="$t(`employee.work_info`)">
      <b-row>
        <b-col md="4" class="mb-3">
          <p class="text-muted mb-0">{{ $t("employee.job_title") }}</p>
          {{
            job_titles.find(
              (j) => parseInt(j.id) === parseInt(employee.job_title_id)
            )?.name || "---"
          }}
        </b-col>
        <b-col md="4" class="mb-3">
          <p class="text-muted mb-0">{{ $t("employee.contract_type") }}</p>
          {{ $t(`contract_type.${employee.contract_type}`) }}
        </b-col>
        <b-col md="4" class="mb-3">
          <p class="text-muted mb-0">
            {{ $t("employee.contract_start_date") }}
          </p>
          {{ employee.contract_start | moment("DD/MM/YYYY") }}
        </b-col>
        <b-col md="4" class="mb-3">
          <p class="text-muted mb-0">{{ $t("employee.contract_end_date") }}</p>
          {{ employee.contract_end | moment("DD/MM/YYYY") }}
        </b-col>
        <b-col md="4" class="mb-3">
          <p class="text-muted mb-0">{{ $t("employee.probation_date") }}</p>
          {{ employee.probation_date | moment("DD/MM/YYYY") }}
        </b-col>
        <b-col md="4" class="mb-3">
          <p class="text-muted mb-0">{{ $t("employee.work_plan_type") }}</p>
          {{
            $t(
              `work_plan_type.${
                work_plans.find(
                  (p) => parseInt(p.id) === parseInt(employee.work_plan_id)
                )?.type
              }`
            )
          }}
        </b-col>
        <b-col md="4" class="mb-3">
          <p class="text-muted mb-0">{{ $t("employee.work_plan_name") }}</p>
          {{
            work_plans.find(
              (p) => parseInt(p.id) === parseInt(employee.work_plan_id)
            )?.name || "---"
          }}
        </b-col>
      </b-row>
    </b-card>
    <b-card :title="$t('employee.salary_info')">
      <b-row>
        <b-col md="4" class="mb-3">
          <p class="text-muted mb-0">
            {{ $t(`employee.salary.gross`) }}
          </p>
          {{ employee.salary_gross }}
        </b-col>
        <b-col md="4" class="mb-3">
          <p class="text-muted mb-0">
            {{ $t(`employee.salary.net`) }}
          </p>
          {{ employee.salary_net }}
        </b-col>
        <b-col md="4" class="mb-3">
          <p class="text-muted mb-0">
            {{ $t(`employee.salary.taxes`) }}
          </p>
          {{ employee.salary_taxes }}
        </b-col>
        <b-col md="4" class="mb-3">
          <p class="text-muted mb-0">
            {{ $t(`employee.salary.social`) }}
          </p>
          {{ employee.salary_social }}
        </b-col>
        <b-col md="4" class="mb-3">
          <p class="text-muted mb-0">
            {{ $t(`employee.salary.social_employee`) }}
          </p>
          {{ employee.salary_social_employee }}
        </b-col>
        <b-col md="4" class="mb-3">
          <p class="text-muted mb-0">
            {{ $t(`employee.salary.social_company`) }}
          </p>
          {{ employee.salary_social_company }}
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>